import React from "react"
import Layout from "../components/layout"
import GongNengMoKuai from "../components/gong-neng-mo-kuai"

import "./index.scss"

class HomePage extends React.Component {

  render() {
    return (
      <Layout pageId={"/"}>
        <div className="container-fluid bg-wave-top">
          <div className="container py-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-6">
                <h1 style={{color:'white', fontWeight:'bold', fontStyle:'italic'}}>资产运营管理 SaaS</h1>
                <p className="text-white">
                  果心科技专注于应用新一代信息技术，为园区、社区、公租房、写字楼、孵化器等多形态资产运营管理提供SaaS综合管理平台和软硬整体件解决方案。
                </p>
                <p className="text-white">
                  人工智能、物联网、5G、云计算等新一代信息技术快速发展，为各行业带来了新一轮技术升级周期。
                  在供给侧改革与数字新基建的大背景下，传统行业正在经历信息技术的全方位重塑。在这场信息化的"军备竞赛"中，能够<i>应用信息化技术</i>、能够<i>吸纳信息化人才</i>、能够<i>成长出信息化基因</i>的传统企业将在竞争中获胜。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img src="../images/homepage/1_1.png" className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row align-items-center justify-content-center flex-md-row-reverse">
            <div className="col-12 col-md-6 pl-md-5">
                <h1 style={{}}>企业园区</h1>
                <p>
                  企业园区包括新兴的产业园、孵化器，以及“传统”的写字楼。企业园区由运营方、入驻企业、员工三方主体组成。典型的企业园区运营方组织架构包括综合部、招商部、运营部、物业部、财务部等。
                </p>
                <a type="button" href="/park" className="btn btn-light btn-sm btn-text">了解详情</a>
            </div>
            <div className="col-12 col-md-6">
              <img src="../images/chahua/2.png" className="img-fluid" alt=""/>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-wave">
          <div className="container py-5">
            <div className="row align-items-center justify-content-center py-5">
              <div className="col-12 col-md-6 py-5">
                <h1 className="text-white">住宅社区</h1>
                <p style={{color: "white"}}>
                  随着地产开速度放缓以及新政策对地厂商自持物业的要求，地产与物业企业正在寻求信息化突破。物业是连接小区居民、外部服务以及基层社区、乡镇政府的桥梁。智慧物业正在成为未来社区管理的基础石。典型的小区物业公司组织架构包括办公室、客服部、工程部、保安部、保洁部等。
                </p>
                <a type="button" href="/community" className="btn btn-light btn-sm btn-text">了解详情</a>
              </div>
              <div className="col-12 col-md-6">
                <img src="../images/homepage/3_1.png" className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>

        <GongNengMoKuai/>

        <h1 className="text-center my-5">
          系统构成
        </h1>
        <div className="container my-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6">
              <img src="../images/homepage/4.png" className="img-fluid" alt=""/>
            </div>
            <div className="col-12 col-md-6">
              <div className="row ">
                <div className="col func-icon" >
                  <img src="../images/homepage/4_2.png" alt=""/>
                  <div className="title">管理方SaaS后台</div>
                </div>
                <div className="col func-icon">
                  <img src="../images/homepage/4_3.png" alt=""/>
                  <div className="title">管理方APP</div>
                </div>
              </div>
              <div className="row">
                <div className="col func-icon">
                  <img src="../images/homepage/4_1.png" alt=""/>
                  <div className="title">用户端APP</div>
                </div>
                <div className="col func-icon">
                  <img src="../images/homepage/4_6.png" alt=""/>
                  <div className="title">用户端小程序</div>
                </div>
              </div>
              <div className="row">
                <div className="col func-icon" >
                  <img src="../images/homepage/4_4.png" className="sys-icon" alt=""/>
                  <div className="title">智能硬件终端</div>
                </div>
                <div className="col func-icon">
                  <img src="../images/homepage/4_5.png" className="sys-icon" alt=""/>
                  <div className="title">三方服务集成</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default HomePage
